import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useAuthContext } from './hooks/useAuthContext';

import Home from './pages/home/Home';
import Login from './pages/login/Login';
import Signup from './pages/signup/Signup';
import Navbar from './components/Navbar';
import StatesVisited from './pages/geography/StatesVisited';
import Softball from './pages/softball/Softball';
import 'bootstrap/dist/css/bootstrap.css';

function App() {
  const { authIsReady } = useAuthContext();

  return (
    <div className="App">
      {authIsReady && (
        <BrowserRouter>
          <Navbar />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/softball" element={<Softball />} />
            <Route path="/states-visited" element={<StatesVisited />} />
            <Route path="/states-visited/:id" element={<StatesVisited />} />
          </Routes>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
