// eslint-disable react-hooks/rules-of-hooks
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-no-bind */
import React from 'react';
import { Row, Col } from 'react-bootstrap';

import styles from './Softball.module.css';

export default function Softball() {
  let seasons = [
    { logo: 'web_2007_DemosDirtyDozen.jpg', name: "Demo's Dirty Dozen", year: 2007, record: '4-14' },
    { logo: 'web_2008_Demoberries.jpg', name: 'Demoberries', year: 2008, record: '2-16' },
    { logo: 'web_2009_Sliminaiders.jpg', name: 'Sliminaiders', year: 2009, record: '6-10' },
    { logo: 'web_2010_SlimiHendrix.jpg', name: 'Slimi Hendrix', year: 2010, record: '6-12' },
    { logo: 'web_2011_WorleyBirds.jpg', name: 'Worleybirds', year: 2011, record: '9-9' },
    { logo: 'web_2012_FightingGrimaces.jpg', name: 'Fighting Grimaces', year: 2012, record: '12-6 2nd - 0-1 playoffs' },
    { logo: 'web_2013_ChewPorkka.jpg', name: 'ChewPorkka', year: 2013, record: '6-12' },
    { logo: 'web_2014_TilkRestyling.jpg', name: 'Tilk Restyling', year: 2014, record: '10-8 4th - 0-1 playoffs' },
    {
      logo: 'web_2015_SoberSilverBacks.jpg',
      name: 'Sober Silverbacks',
      year: 2015,
      record: '10-8 3rd - 0-1 playoffs | Fall: 7-0 1st - 1-1 playoffs 2nd',
    },
    { logo: 'web_2016_PattyCakes.jpg', name: 'PattyCakes', year: 2016, record: '9-9 - 1-1 playoffs' },
    { logo: 'web_2017_Demos.jpg', name: 'Demos XI', year: 2017, record: '7-11 - 0-1 playoffs' },
    { logo: 'web_2018_CryingChesterCheetahs.jpg', name: 'Chester Cheetahs', year: 2018, record: '6-12 - 0-1 playoffs' },
  ];

  return (
    <>
      <div className={styles['softball-container']}>
        <div>
          <div>
            <span></span>
            <span className={styles['overall-record']}>Total Record: 96-134 </span>
            <span className={styles['percentage']}>.417%</span>
          </div>
          <br></br>
          <div className={styles['record-breakdown']}>
            <div>Total Summer Record: 87-127</div>
            <div>Total Summer Playoff Record: 1-6</div>
            <br></br>
            <div>Total Fall Record: 7-0</div>
            <div>Total Fall Playoff Record: 1-1</div>
            <br></br>
          </div>
        </div>
        <Row>
          {seasons.map((season) => (
            <Col xs={12} md={6} lg={4} className={styles['season-container']}>
              <div>
                <span className={styles['season-name']}>{season.name}</span>
                <span className={styles['season-year']}>{season.year}</span>
              </div>
              <div>{season.record}</div>
              <div className={styles['logo-container']}>
                <img src={`/softball-logos/${season.logo}`} width="100%" height="100%" />
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}
