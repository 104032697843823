function data() {
  return {
    STL: {
      cx: '574.3',
      cy: '284.8',
      name: 'St. Louis',
    },
    MER: {
      cx: '564.3',
      cy: '290.8',
      name: 'Meramac State Park',
      photosURL: 'https://photos.app.goo.gl/6YrqXW1ixtRekkXt6',
    },
    EUR: {
      cx: '554.3',
      cy: '294.8',
      name: 'Six Flags',
    },
    KIN: {
      cx: '594.3',
      cy: '294.8',
      name: 'Kinkaid',
    },
    MUR: {
      cx: '604.3',
      cy: '294.8',
      name: 'Murphysboro',
    },
    SPR: {
      cx: '594.3',
      cy: '260.8',
      name: 'Double J',
    },
    SCH: {
      cx: '610.3',
      cy: '230.8',
      name: 'Schaumburg',
    },
    MIL: {
      cx: '600.3',
      cy: '190.8',
      name: 'Bucks in 6',
    },
    DEL: {
      cx: '590.3',
      cy: '176.8',
      name: 'Dells',
    },
  };
}

export default data;
