import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'

const firebaseConfig = {
    apiKey: "AIzaSyCtedhgIagriMGOwRY1y00KGFwHOflhEDQ",
    authDomain: "gogoplanet-294d1.firebaseapp.com",
    projectId: "gogoplanet-294d1",
    storageBucket: "gogoplanet-294d1.appspot.com",
    messagingSenderId: "512172562953",
    appId: "1:512172562953:web:863ced0f43817b76acf6d8",
    measurementId: "G-F1LPLEW3M1"
}

// init firebase
firebase.initializeApp(firebaseConfig);

// init services
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();

// timestamp
const timestamp = firebase.firestore.Timestamp
// const analytics = getAnalytics(app);

export { projectFirestore, projectAuth, timestamp }