import React from 'react';

const USACity = (props) => {
  return (
    <circle
      style={{ cursor: 'pointer' }}
      className={props.name}
      onClick={props.onClickCity}
      data-name={props.name}
      data-photosURL={props.photosURL}
      fill="#FFFF00"
      stroke="#000000"
      strokeWidth="1.5"
      cx={props.cx}
      cy={props.cy}
      r="5"
      opacity="1"
    >
      <title>{props.name}</title>
    </circle>
  );
};
export default USACity;
