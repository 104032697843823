// eslint-disable react-hooks/rules-of-hooks
import StatesBaseMap from './StatesBaseMap.js';
import StatesDropdown from './StatesDropdown.js';

export default function StatesVisited() {
  return (
    <>
      <StatesDropdown></StatesDropdown>
      <StatesBaseMap></StatesBaseMap>
    </>
  );
}
