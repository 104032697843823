import { useState, useEffect } from 'react';
import { projectFirestore } from '../../firebase/config';
import { useNavigate } from 'react-router-dom';
import styles from './Geography.module.css';

export default function StatesDropdown() {
  const navigate = useNavigate();

  const [data, setData] = useState(null);
  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
    navigate(`/states-visited/${event.target.value}`);
  };

  useEffect(() => {
    const unsub = projectFirestore.collection('states-visited').onSnapshot((snapshot) => {
      let results = [];
      snapshot.docs.forEach((doc) => {
        results.push({ ...doc.data(), id: doc.id });
      });
      results.sort((a, b) => (a.traveler < b.traveler ? -1 : a.traveler > b.traveler ? 1 : 0));
      setData(results);
    });

    return () => unsub();
  }, []);

  return (
    <>
      {data && (
        <div className={styles['states-dropdown']}>
          <label htmlFor="dropdown">Traveler:</label>
          <select id="dropdown" value={selectedOption} onChange={handleChange}>
            <option key="" value="">
              Select an traveler
            </option>
            {data.map((trav) => (
              <option key={trav.id} value={trav.id}>
                {trav.traveler}
              </option>
            ))}
          </select>
        </div>
      )}
    </>
  );
}
