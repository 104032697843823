import { Row, Col } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/cjs/Button';
import Container from 'react-bootstrap/Container';

export default function USACityPhotoModal({ modalInfo, showModal, handleClose }) {
  if (!modalInfo) {
    return null;
  }
  return (
    <>
      <Modal show={showModal} dialogClassName="modal-50w" aria-labelledby="state-modal">
        <Modal.Header>
          <Modal.Title>{modalInfo.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col md={1} />
              <Col md={10}>
                {modalInfo.photosURL && (
                  <a href={modalInfo.photosURL} target="_blank">
                    See pictures
                  </a>
                )}
                {!modalInfo.photosURL && <div>GoGo's lazy ass hasn't uploaded any pictures for this yet</div>}
              </Col>
              <Col md={1} />
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className="row align-items-center">
              <Col className="text-center mt-2 mb-2">
                <Button variant="secondary" onClick={handleClose}>
                  Close
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </>
  );
}
