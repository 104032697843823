import React from 'react';
import { useState } from 'react';
import stateData from './USAStateDimensions.js';
import USAState from './USAState.js';
import cityData from './USACityDimensions.js';
import USACity from './USACity.js';
import USACityPhotoModal from './USACityPhotoModal.js';

export default function USAMap({ visitedStateData, defaultFill, travelerID }) {
  const [modalInfo, setModalInfo] = useState(null);
  const [showModal, setShowModal] = useState(false);

  function handleClose() {
    setModalInfo(null);
    setShowModal(false);
  }

  const fillStateColor = (state) => {
    if (visitedStateData && visitedStateData[state] && visitedStateData[state].fill) {
      return visitedStateData[state].fill;
    }

    return defaultFill;
  };

  function cityClick(city) {
    setModalInfo(city.target.dataset);
    setShowModal(true);
  }

  const buildStates = () => {
    let paths = [];
    let states = stateData();
    for (let stateKey in states) {
      const path = <USAState key={stateKey} stateName={states[stateKey].name} dimensions={states[stateKey]['dimensions']} state={stateKey} fill={fillStateColor(stateKey)} />;
      paths.push(path);
    }
    return paths;
  };

  const buildCities = () => {
    let circles = [];
    if (travelerID === '4ysA4YWp8zyRH3RQWInA') {
      let cities = cityData();
      for (let circleKey in cities) {
        const circle = (
          <USACity
            key={circleKey}
            name={cities[circleKey].name}
            photosURL={cities[circleKey].photosURL}
            cx={cities[circleKey].cx}
            cy={cities[circleKey].cy}
            onClickCity={cityClick.bind(circleKey)}
          />
        );
        circles.push(circle);
      }
    }
    return circles;
  };

  return (
    <>
      <USACityPhotoModal modalInfo={modalInfo} showModal={showModal} handleClose={handleClose} />
      <svg className="us-state-map" xmlns="http://www.w3.org/2000/svg" width="959" height="593" viewBox="0 0 959 593">
        <g className="outlines">
          {buildStates()}
          <g className="DC state">
            <path className="DC1" fill={fillStateColor('DC1')} d="M801.8,2fff53.8 l-1.1-1.6 -1-0.8 1.1-1.6 2.2,1.5z" />
            <circle className="DC2" data-name={'DC'} fill={fillStateColor('DC')} stroke="#FFFFFF" strokeWidth="1.5" cx="801.3" cy="251.8" r="5" opacity="1">
              <title>Washington DC</title>
            </circle>
          </g>
          <g className="test">{buildCities()}</g>
        </g>
      </svg>
    </>
  );
}
