// eslint-disable react-hooks/rules-of-hooks
import { useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import USAMap from './USAMap.js';
import { projectFirestore } from '../../firebase/config';

import styles from './Geography.module.css';

export default function StatesBaseMap() {
  const { id } = useParams();

  const [visitedStateData, setVisitedStateData] = useState(null);
  const [traveler, setTraveler] = useState('');
  const [visitedColor, setVisitedColor] = useState('');
  const [notVisitedColor, setNotVisitedColor] = useState('');
  const [stateCount, setStateCount] = useState('');
  const [isPending, setIsPending] = useState(false);
  const [error, setError] = useState(false);
  const [dcVisited, setdcVisited] = useState(false);

  useEffect(() => {
    setIsPending(true);

    const unsub = projectFirestore
      .collection('states-visited')
      .doc(id)
      .onSnapshot((doc) => {
        if (doc.exists) {
          const statesVisited = doc.data();

          getStateCount(statesVisited.states);

          setTraveler(statesVisited.traveler);

          setVisitedColor(statesVisited.visitedColor || 'blue');
          setNotVisitedColor(statesVisited.defaultColor || 'orange');

          const populateData = {};
          statesVisited.states.forEach((obj) => {
            populateData[obj] = { fill: visitedColor };
          });
          setVisitedStateData(populateData);

          setIsPending(false);
          setError(false);
        } else {
          setTraveler(null);
          setIsPending(false);
          setError(`Could not find that traveler`);
        }
      });

    return () => unsub();
  }, [id, notVisitedColor]);

  function getStateCount(statesList) {
    const hasDC = statesList.includes('DC');
    setdcVisited(hasDC);
    setStateCount(hasDC ? statesList.length - 1 : statesList.length);
  }

  return (
    <>
      {error && <p className="error">{error}</p>}
      {isPending && <p className="loading">Loading...</p>}
      {visitedStateData && traveler && visitedColor && notVisitedColor && (
        <>
          <div className={styles['legend']}>
            <div className={styles['legend-box']} style={{ background: visitedColor }}>
              <div className={styles['legend-text']}>Visited</div>
            </div>
            <div className={styles['legend-box']} style={{ background: notVisitedColor }}>
              <div className={styles['legend-text']}>Not Visited</div>
            </div>
          </div>
          <div className={styles['svg-container']}>
            <h2>{traveler}</h2>
            <div className={styles['count-container']}>
              {stateCount} states{dcVisited && <span> + DC</span>}
            </div>
            <USAMap visitedStateData={visitedStateData} defaultFill={notVisitedColor} travelerID={id} />
          </div>
        </>
      )}
    </>
  );
}
